import React, { useState} from "react";
import styles from "./DobleSelect.module.css";
import { defautlVariables } from "../../const";
import { useNavigate } from "react-router-dom";

const DoubleSelect = () => {
  const [selectOne, setSelectOne] = useState(0);
  const [selectTwo, setSelectTwo] = useState(0);
  const navigate = useNavigate()

  const handleClick = (event) =>{
    if(selectOne !== '' && selectTwo !== ''){
      const data = defautlVariables
     
      data.tipoDeTrasporte = selectOne
      data.tipoDeCarga = selectTwo

      const result = JSON.stringify(data)
      sessionStorage.setItem('DatosContacto',result)
      
    }
    
    navigate('/Contacto')
  }

  const SelectOneChange = (event)=>{
    const value = event.target.value
    setSelectOne(value)
  }  
  const SelectTwoChange = (event)=>{
    const value = event.target.value
    setSelectTwo(value)
  }

  return (
    <div className={styles.serviseBar}>
      <select onChange={SelectOneChange}  className={styles.OptionsSelect}>
        <option defaultValue={0} disabled selected>Tipo de Trasporte</option>
        <option value={1}>Furgon</option>
        <option value={2}>Carry</option>
        <option value={3}>Camión</option>
        <option value={4}>Grua</option>
        <option value={5}>Aereo</option>
      </select>
      <select onChange={SelectTwoChange} className={styles.OptionsSelect}>
        <option defaultValue={0} disabled selected>Tipo de Carga</option>
        <option value={1}>Seca</option>
        <option value={2}>Refrigerada</option>
        <option value={3}>Gruas</option>
        <option value={4}>Maquinaria y equipos</option>
      </select>

      <div onClick={handleClick} className={styles.contBoton}>
        <p>Cotizar</p>
      </div>
    </div>
  );
};

export default DoubleSelect;

/*
<div onClick={handleSelectOne} id="tipoTrasporte">
        <p>Tipo de Trasporte</p>
        <svg
          width="20"
          height="13"
          viewBox="0 0 20 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1818 12.456C10.531 13.1813 9.47416 13.1813 8.82337 12.456L8.81817 12.4618L0.488091 3.1782C-0.162697 2.45291 -0.162697 1.27505 0.488091 0.549766C1.13888 -0.175518 2.19576 -0.175518 2.84654 0.549766L8.33398 6.65956L10 8.21457L11.666 6.66536L17.1535 0.543963C17.8042 -0.181321 18.8611 -0.181321 19.5119 0.543963C20.1627 1.26925 20.1627 2.44711 19.5119 3.1724L11.1818 12.456Z"
            fill="black"
          />
        </svg>
      </div>


      <div>
        <p>Tipo de Carga</p>
        <svg
          width="20"
          height="13"
          viewBox="0 0 20 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1818 12.456C10.531 13.1813 9.47416 13.1813 8.82337 12.456L8.81817 12.4618L0.488091 3.1782C-0.162697 2.45291 -0.162697 1.27505 0.488091 0.549766C1.13888 -0.175518 2.19576 -0.175518 2.84654 0.549766L8.33398 6.65956L10 8.21457L11.666 6.66536L17.1535 0.543963C17.8042 -0.181321 18.8611 -0.181321 19.5119 0.543963C20.1627 1.26925 20.1627 2.44711 19.5119 3.1724L11.1818 12.456Z"
            fill="black"
          />
        </svg>
      </div>
*/
