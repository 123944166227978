import {useState} from "react";
import styleContacto from "../style/contacto.module.css";
import { defautlVariables } from "../const";
import { useNavigate } from "react-router-dom";

const Contacto = () => {
  const [correo,setCorreo] = useState('')
  const navigate = useNavigate()
  const handleChange = (event)=>{
    const text  = event.target.value
    setCorreo(text)
  }

  const handleClick = ()=>{
    if(correo !== ''){
      const data = defautlVariables
      data.correo = correo
      const result = JSON.stringify(data)
      sessionStorage.setItem('DatosContacto',result)
      navigate('/Contacto')
    }
  }

  return (
    <div className={styleContacto.container} id='Contacto'>
      <div>
        <div>
          <p>¿Estás listo para <br/> realizar su envío?</p>
        </div>
        <div className={styleContacto.contInpusEmail}>
          <div>
            <input onChange={handleChange} type="text" placeholder="Correo electrónico" />
          </div>
          <div onClick={handleClick} className={styleContacto.contButton}>
            <span>Habla con <br/> nosotros</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
