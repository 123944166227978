import React from "react";
import footerStyles from "../style/footer.module.css";

const Footer = () => {
  return (
    <div className={footerStyles.container}>
      <div className={footerStyles.contText}>
        <div>
          <h2>Contacto</h2>
          <p>Operaciones</p>
          <p>312 552 0710</p>
        </div>
      </div>
      <div className={footerStyles.contRedes}>
        <div>
          <a href="https://www.facebook.com/profile.php?id=100094815395467&mibextid=LQQJ4d">
            <svg
              width="67"
              height="67"
              viewBox="0 0 67 67"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M67 33.7039C67 15.0852 52.0061 0 33.5 0C14.994 0 0 15.0852 0 33.7039C0 50.5259 12.2505 64.4695 28.2656 67V43.4467H19.7555V33.7039H28.2656V26.2781C28.2656 17.8318 33.2636 13.1662 40.9186 13.1662C44.5847 13.1662 48.4183 13.824 48.4183 13.824V22.1141H44.193C40.0325 22.1141 38.7344 24.7125 38.7344 27.3776V33.7039H48.0252L46.5393 43.4467H38.7344V67C54.7495 64.4695 67 50.5259 67 33.7039Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
        <div>
          <a href="https://www.instagram.com/tme_soluciones_/?igshid=NTc4MTIwNjQ2YQ%3D%3D">
            <svg
              width="67"
              height="67"
              viewBox="0 0 67 67"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.5075 16.322C24.0012 16.322 16.3334 23.9916 16.3334 33.5C16.3334 43.0084 24.0012 50.678 33.5075 50.678C43.0138 50.678 50.6816 43.0084 50.6816 33.5C50.6816 23.9916 43.0138 16.322 33.5075 16.322ZM33.5075 44.6679C27.3643 44.6679 22.3421 39.6595 22.3421 33.5C22.3421 27.3405 27.3493 22.3321 33.5075 22.3321C39.6656 22.3321 44.6729 27.3405 44.6729 33.5C44.6729 39.6595 39.6507 44.6679 33.5075 44.6679ZM55.3899 15.6194C55.3899 17.847 53.5963 19.6261 51.3841 19.6261C49.157 19.6261 47.3783 17.832 47.3783 15.6194C47.3783 13.4067 49.172 11.6127 51.3841 11.6127C53.5963 11.6127 55.3899 13.4067 55.3899 15.6194ZM66.7646 19.6859C66.5105 14.3187 65.2848 9.56449 61.3538 5.64749C57.4376 1.7305 52.6845 0.504574 47.3185 0.235468C41.7881 -0.0784893 25.2119 -0.0784893 19.6815 0.235468C14.3305 0.489624 9.5773 1.71555 5.64624 5.63254C1.71517 9.54954 0.504462 14.3037 0.235416 19.6709C-0.0784718 25.2026 -0.0784718 41.7825 0.235416 47.3141C0.489515 52.6813 1.71517 57.4355 5.64624 61.3525C9.5773 65.2695 14.3155 66.4954 19.6815 66.7645C25.2119 67.0785 41.7881 67.0785 47.3185 66.7645C52.6845 66.5104 57.4376 65.2844 61.3538 61.3525C65.2699 57.4355 66.4955 52.6813 66.7646 47.3141C67.0785 41.7825 67.0785 25.2175 66.7646 19.6859ZM59.6199 53.2494C58.454 56.1797 56.197 58.4372 53.2525 59.6183C48.8431 61.3675 38.3802 60.9638 33.5075 60.9638C28.6347 60.9638 18.1569 61.3525 13.7625 59.6183C10.8328 58.4521 8.57585 56.1946 7.39504 53.2494C5.64624 48.8391 6.04981 38.3738 6.04981 33.5C6.04981 28.6262 5.66118 18.146 7.39504 13.7506C8.5609 10.8203 10.8179 8.56281 13.7625 7.38174C18.1718 5.63254 28.6347 6.0362 33.5075 6.0362C38.3802 6.0362 48.8581 5.64749 53.2525 7.38174C56.1821 8.54786 58.4391 10.8054 59.6199 13.7506C61.3687 18.1609 60.9651 28.6262 60.9651 33.5C60.9651 38.3738 61.3687 48.854 59.6199 53.2494Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
        <div>
          <a href="/Contacto">
            <svg
              width="67"
              height="67"
              viewBox="0 0 67 67"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M56.965 9.73594C50.6987 3.45469 42.3536 0 33.485 0C15.1797 0 0.284152 14.8955 0.284152 33.2009C0.284152 39.0484 1.8096 44.7614 4.71094 49.8013L0 67L17.6025 62.3788C22.448 65.0259 27.9067 66.4167 33.4701 66.4167H33.485C51.7754 66.4167 67 51.5212 67 33.2158C67 24.3473 63.2312 16.0172 56.965 9.73594ZM33.485 60.8234C28.5199 60.8234 23.6594 59.4924 19.427 56.9799L18.425 56.3817L7.98616 59.1185L10.7679 48.9339L10.1098 47.8871C7.34308 43.4902 5.89241 38.4203 5.89241 33.2009C5.89241 17.9913 18.2754 5.60826 33.5 5.60826C40.873 5.60826 47.7973 8.47969 53.0018 13.6991C58.2062 18.9185 61.4067 25.8429 61.3917 33.2158C61.3917 48.4404 48.6946 60.8234 33.485 60.8234ZM48.6199 40.1551C47.7973 39.7364 43.7145 37.7324 42.9518 37.4632C42.1891 37.179 41.6357 37.0444 41.0824 37.8819C40.529 38.7194 38.9437 40.5739 38.4502 41.1422C37.9717 41.6955 37.4781 41.7703 36.6556 41.3516C31.7801 38.9138 28.5797 36.9996 25.3643 31.481C24.5118 30.0154 26.2167 30.1201 27.802 26.9496C28.0712 26.3962 27.9366 25.9176 27.7272 25.4989C27.5179 25.0801 25.8578 20.9973 25.1699 19.3373C24.4969 17.7221 23.8089 17.9464 23.3004 17.9165C22.8219 17.8866 22.2685 17.8866 21.7152 17.8866C21.1618 17.8866 20.2645 18.096 19.5018 18.9185C18.7391 19.756 16.6004 21.76 16.6004 25.8429C16.6004 29.9257 19.5766 33.8739 19.9804 34.4272C20.3991 34.9806 25.8279 43.3556 34.158 46.9598C39.4223 49.233 41.4862 49.4275 44.1183 49.0386C45.7185 48.7993 49.0237 47.0346 49.7116 45.0904C50.3996 43.1462 50.3996 41.4862 50.1902 41.1422C49.9958 40.7683 49.4424 40.5589 48.6199 40.1551Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
