import {useState} from 'react'

const regTel =  /\d{10}/
//eslint-disable-next-line
const regCorreo = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
const regDireccion = /^(autopista|avenida|avdacalle|avdacarrera|avenidacalle|avenidacarrera|avenida|carrera|calle|circunvalar|diagonal|kilometro|transversal|av|avcalle|avcarrera|avcra|avcll|avda|ac|ak|cl|cll|clle|kr|kra|cra|cra.|ccv|dg|dg.|diag|km|tv|autop)([0-9]{1,3})([a-z]{0,1})(bis[a-z]{0,1})?(este|norte|occidente|oeste|sur)?(#|n°|n|\s*|no|No|N|N°)([0-9]{1,3})([a-z]{0,1})(bis[a-z]{0,1})?(este|norte|occidente|oeste|sur)?(-|\s*)([0-9]{1,3})(este|norte|occidente|oeste|sur)?$/
const regUser = /^[a-z0-9]+$/
const regPassword = /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/

export const Validaciones = (input)=>{
    if(input.text === ''){
        input.setError('Esta casilla es obligatoria')
        return true
    }
    if(input.type === 'email'){
        if(!regCorreo.test(input.text)){
            input.setError('Dirección de correo invalida')
            return  true
        }
    }
    if(input.type === 'number'){
      
        if(isNaN(input.text)){
            input.setError('Esta casilla solo acepta números')
            return true
        }  
    }
    if(input.type === 'addrees'){
        let textData =  input.text.toLowerCase()
        let textSinEspacion = textData.trim()
        textSinEspacion = textData.replace(/ /g,'')

        if(!regDireccion.test(textSinEspacion)){
            input.setError('Dirección invalida (ej:cra23a bis#127Sur-13)')
            return true
        }
    }
    if(input.type === 'phoneNumber'){
        let textSinEspacion = input.text.replace(/ /g,'')
        if(!regTel.test(textSinEspacion)){
            input.setError('Esta casilla solo acepta números y 10 caracteres')
            return true
        }   
    }
    if(input.type === 'user'){
        let texto = input.text
        if(!regUser.test(texto.trim())){
            input.setError('Esta casilla no acepta espacios, caracteres especiales o mayúsculas')
            return true
        }   
    }
    if(input.type === 'password'){
        let texto = input.text

        if(!regPassword.test(texto)){
            input.setError('Su contraseña debe contener por lo menos un número, una mayúscula, un caracter especial y 8 caracteres ($-?¡#_)')
            return true
        }  
    }

    return false
}

export const useInputHandles = ({type})=>{
    const [error,setError] = useState('')
    const [text,setText] = useState('')
    
    
    const handleChange = (text)=>{
        if(error !== ''){
            setError('')
        }
        setText(text)
    }

    const handleBlur = ()=>{
        Validaciones({text,type,setError})
        if(error !== '') return setError('')     
    }

    const handleFocus = ()=>{
        if(error !== '') return setError('')  
    }

    return{
       error,
       text,
       handleChange,
       handleBlur,
       handleFocus,
       setError,
       type
    }
}