import React from 'react'

const LogoTME = ({id}) => {
  return (
    <svg
          id={id}
          data-name="Capa 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 720 720"
        >
          <g>
            <path
              fill="#69b32d"
              d="m49.17,512.61c0,4.87-3.85,7.54-8.92,7.54-4.31,0-7.28-1.74-9.02-4.2l4-3.23c1.08,1.54,2.51,2.67,4.87,2.67s3.69-1.13,3.69-2.67c0-1.85-1.49-2.51-3.95-3.59l-1.33-.56c-3.9-1.69-6.46-3.74-6.46-8.15,0-4.05,3.08-7.13,7.9-7.13,3.03,0,5.33.97,7.02,3.38l-3.85,3.18c-.87-1.33-1.79-1.85-3.18-1.85-1.59,0-2.62,1.03-2.62,2.31,0,1.64,1.03,2.26,3.33,3.28l1.33.56c4.61,1.95,7.18,3.95,7.18,8.46Z"
            />
            <path
              fill="#69b32d"
              d="m82.55,506.71c0,7.38-6,13.43-13.38,13.43s-13.43-6.05-13.43-13.43,6-13.43,13.43-13.43,13.38,6,13.38,13.43Zm-4.82,0c0-4.77-3.85-8.56-8.56-8.56s-8.61,3.79-8.61,8.56,3.85,8.56,8.61,8.56,8.56-3.85,8.56-8.56Z"
            />
            <path
              fill="#69b32d"
              d="m108.39,514.86v5.28h-18.2v-26.82h5.28v21.54h12.92Z"
            />
            <path
              fill="#69b32d"
              d="m138.65,493.28v15.95c0,6.05-4.92,10.92-10.92,10.92s-10.92-4.87-10.92-10.92v-15.95h4.97v15.95c0,3.28,2.67,5.95,5.95,5.95s5.9-2.67,5.9-5.95v-15.95h5.02Z"
            />
            <path
              fill="#69b32d"
              d="m172.8,513.43c-2.36,4.05-6.67,6.72-11.64,6.72-7.44,0-13.43-6-13.43-13.43s6-13.48,13.43-13.48c4.92,0,9.23,2.67,11.54,6.61l-4.1,2.82c-1.44-2.62-4.2-4.41-7.44-4.41-4.67,0-8.46,3.79-8.46,8.46s3.8,8.46,8.46,8.46c3.18,0,5.9-1.74,7.33-4.31l4.31,2.56Z"
            />
            <path fill="#69b32d" d="m179.36,520.14v-26.82h5.28v26.82h-5.28Z" />
            <path
              fill="#69b32d"
              d="m218.58,506.71c0,7.38-6,13.43-13.38,13.43s-13.43-6.05-13.43-13.43,6-13.43,13.43-13.43,13.38,6,13.38,13.43Zm-4.82,0c0-4.77-3.85-8.56-8.56-8.56s-8.61,3.79-8.61,8.56,3.85,8.56,8.61,8.56,8.56-3.85,8.56-8.56Z"
            />
            <path
              fill="#69b32d"
              d="m248.53,493.33v26.82h-5.28v-2.41l-11.64-15.59v18h-5.28v-26.82h5.28l11.64,15.59v-15.59h5.28Z"
            />
            <path
              fill="#69b32d"
              d="m274.88,514.86v5.28h-18.51v-26.82h17.64v5.28h-12.36v8.15h7.85v5.28h-7.85v2.82h13.23Z"
            />
            <path
              fill="#69b32d"
              d="m300.26,512.61c0,4.87-3.85,7.54-8.92,7.54-4.31,0-7.28-1.74-9.03-4.2l4-3.23c1.08,1.54,2.51,2.67,4.87,2.67s3.69-1.13,3.69-2.67c0-1.85-1.49-2.51-3.95-3.59l-1.33-.56c-3.9-1.69-6.46-3.74-6.46-8.15,0-4.05,3.08-7.13,7.9-7.13,3.03,0,5.33.97,7.02,3.38l-3.85,3.18c-.87-1.33-1.79-1.85-3.18-1.85-1.59,0-2.62,1.03-2.62,2.31,0,1.64,1.03,2.26,3.33,3.28l1.33.56c4.62,1.95,7.18,3.95,7.18,8.46Z"
            />
            <path
              fill="#69b32d"
              d="m346.97,514.86v5.28h-18.51v-26.82h17.64v5.28h-12.36v8.15h7.84v5.28h-7.84v2.82h13.23Z"
            />
            <path
              fill="#69b32d"
              d="m376.71,493.33v26.82h-5.28v-2.41l-11.64-15.59v18h-5.28v-26.82h5.28l11.64,15.59v-15.59h5.28Z"
            />
            <path
              fill="#69b32d"
              d="m427.17,498.56h-7.85v21.59h-5.28v-21.59h-7.85v-5.28h20.97v5.28Z"
            />
            <path
              fill="#69b32d"
              d="m455.83,520.14h-5.49l-2.05-3.49c-1.64.87-3.54,1.33-5.54,1.33h-1.79v2.15h-5.28v-26.82h7.08c6.82,0,12.36,5.54,12.36,12.31,0,3.13-1.18,5.95-3.03,8.1l3.74,6.41Zm-13.08-6.97c4.1,0,7.49-3.38,7.49-7.54s-3.38-7.49-7.49-7.49h-1.79v15.02h1.79Z"
            />
            <path
              fill="#69b32d"
              d="m490.54,520.14h-5.28l-.87-2.15c-2.1,1.38-4.62,2.15-7.28,2.15s-5.18-.77-7.23-2.15l-.87,2.15h-5.28l10.77-26.82h5.28l10.77,26.82Zm-8.05-6.82l-3.79-9.43-1.59-3.95-5.33,13.38c1.43,1.18,3.28,1.9,5.33,1.9s3.95-.72,5.38-1.9Z"
            />
            <path
              fill="#69b32d"
              d="m519.77,493.33v26.82h-5.28v-2.41l-11.64-15.59v18h-5.28v-26.82h5.28l11.64,15.59v-15.59h5.28Z"
            />
            <path
              fill="#69b32d"
              d="m545.56,512.61c0,4.87-3.85,7.54-8.92,7.54-4.31,0-7.28-1.74-9.03-4.2l4-3.23c1.08,1.54,2.51,2.67,4.87,2.67s3.69-1.13,3.69-2.67c0-1.85-1.49-2.51-3.95-3.59l-1.33-.56c-3.9-1.69-6.46-3.74-6.46-8.15,0-4.05,3.08-7.13,7.9-7.13,3.03,0,5.33.97,7.03,3.38l-3.85,3.18c-.87-1.33-1.8-1.85-3.18-1.85-1.59,0-2.61,1.03-2.61,2.31,0,1.64,1.03,2.26,3.33,3.28l1.33.56c4.62,1.95,7.18,3.95,7.18,8.46Z"
            />
            <path
              fill="#69b32d"
              d="m572.37,505.63c0,6.82-5.54,12.36-12.3,12.36h-2.51v2.15h-5.28v-26.82h7.79c6.77,0,12.3,5.54,12.3,12.31Zm-4.82,0c0-4.1-3.38-7.49-7.49-7.49h-2.51v15.02h2.51c4.1,0,7.49-3.38,7.49-7.54Z"
            />
            <path
              fill="#69b32d"
              d="m607.03,506.71c0,7.38-6,13.43-13.38,13.43s-13.43-6.05-13.43-13.43,6-13.43,13.43-13.43,13.38,6,13.38,13.43Zm-4.82,0c0-4.77-3.85-8.56-8.56-8.56s-8.61,3.79-8.61,8.56,3.85,8.56,8.61,8.56,8.56-3.85,8.56-8.56Z"
            />
            <path
              fill="#69b32d"
              d="m634.83,520.14h-5.49l-2.05-3.49c-1.64.87-3.54,1.33-5.54,1.33h-1.79v2.15h-5.28v-26.82h7.08c6.82,0,12.36,5.54,12.36,12.31,0,3.13-1.18,5.95-3.03,8.1l3.74,6.41Zm-13.08-6.97c4.1,0,7.49-3.38,7.49-7.54s-3.38-7.49-7.49-7.49h-1.79v15.02h1.79Z"
            />
            <path
              fill="#69b32d"
              d="m663.69,498.56h-7.85v21.59h-5.28v-21.59h-7.85v-5.28h20.97v5.28Z"
            />
            <path
              fill="#69b32d"
              d="m690.71,514.86v5.28h-18.51v-26.82h17.64v5.28h-12.36v8.15h7.85v5.28h-7.85v2.82h13.23Z"
            />
          </g>
          <path
            fill="#71b52b"
            d="m384.41,330.73l-.02.08s-.04.02-.04.02c0-.02.02-.04.06-.1Z"
          />
          <path
            fill="#264897"
            d="m128.36,473.79v-221.95H30.07c-.68-6.09-1.04-13.34-.57-21.47.52-9.09,1.94-17,3.54-23.45,218.89-.19,437.78-.39,656.67-.58v45.5H190.43v221.95h-62.07Z"
          />
          <g>
            <path
              fill="#69b32d"
              d="m127.53,252.26H30.14c6.14,53.69,45.7,97.2,97.38,109.21v-109.21Z"
            />
            <path
              fill="#69b32d"
              d="m279.43,205.45c-14.26-54.61-63.92-94.92-123.01-94.92s-108.88,40.41-123.07,95.14l246.08-.22Z"
            />
            <path
              fill="#69b32d"
              d="m191.26,252.26v107.68c12.13-3.46,23.52-8.66,33.88-15.33,0-28.23,0-56.46,0-84.69h.2c18.75,0,37.5.01,56.24.02.45-2.54.8-5.1,1.1-7.69h-91.42Z"
            />
          </g>
          <g>
            <path
              fill="#69b32d"
              d="m578.37,263.09c-8.8,1.01-31.57,4.35-68.56,21.22-8.83,4.03-21.06,10.01-35.24,18.31-13.86,8.51-32.62,21.21-53.21,39.14-50.53,41.22-91.13,91.41-104.17,133.16,23.43.07,46.87.15,70.3.22-9.04-.44-18.08-.87-27.12-1.31,2.6-6.48,5.19-12.97,7.79-19.45l22.43.6-3.1,20.16,53.98.17c3.46-19.08,16.35-82.26,54.98-134.58,28.86-39.08,63.34-60.28,68.33-63.01,7.64-4.17,11.98-6.06,11.98-6.06,11.34-4.95,13.43-5.75,13.43-5.75,3.4-1.08,7.33-2.5,7.18-3.3-.3-1.64-17.45.3-18.99.48Zm-180.99,178.1c-5.9-.13-13.83-1.16-19.44-2.52,6.78-12.9,7.74-32.82,28.5-23.74-3.02,8.75-6.04,17.51-9.05,26.26Zm25.14-55.83c-1.8,1.55-9.66,22.57-13.59,20.49-2.41-1.27-13.72-1.54-10.56-6.47,1.7-1.21,12.74-20.92,15.79-19.17,2.47.83,10.53.72,8.35,5.15Zm18.65-26.9l-10.97,14.88c-1.94,2.85-5.6.59-7.85-.56-6.79-2.88,8.86-16.63,9.83-19.74,2.76-3.69,12.1,1.26,8.99,5.42Zm23.57-27.23l-14.54,12.19c-1.5,1.32-3.36.2-4.48-.98-4.72-3.14,14.3-13.28,15.38-15.24,2.5-1.96,6.18,1.97,3.65,4.03Zm15.36-11.5c-2.38.76-2.33-3.67-.46-4.38,0,0,16.16-9.98,16.16-9.98,5.68,1.59-3.27,6.49-15.69,14.36Z"
            />
            <polygon
              fill="#69b32d"
              points="360.37 473.84 387.69 473.84 387.49 475.14 360.37 474.93 360.37 473.84"
            />
          </g>
          <g>
            <path
              fill="#264897"
              d="m428.65,260.45l-74.59,107.43-72.53-107.43c-18.61-.07-37.22-.13-55.83-.2-.03,71.69-.07,143.38-.1,215.07h56.75v-124.93l51.5,74.28h6.15l28.34-35.63c16.61-18.34,37.32-38.56,62.66-58.57,18.31-14.45,36.05-26.43,52.43-36.34v-33.68h-54.78Z"
            />
            <path
              fill="#264897"
              d="m443.6,475.14c3.38-16.85,8.33-36.07,15.68-56.81,7.52-21.22,15.98-39.63,24.15-55.1v112.08c-13.28-.06-26.55-.11-39.83-.17Z"
            />
          </g>
          <path
            fill="#264897"
            d="m689.86,427.53v46.26h-171.53v-156.71c9.03-9.55,21.49-21,37.84-31.8,17.24-11.4,33.49-18.57,46.29-23.15h88.18v45.96h-117.91v36.89h102.61v42.03h-102.61v40.52h117.14Z"
          />
          <path
            fill="#264897"
            d="m572.88,262.13h-54.55v15.12c7.63-3.33,16.63-6.7,26.91-9.54,10.14-2.8,19.47-4.52,27.64-5.57Z"
          />
        </svg>
  )
}

export default LogoTME