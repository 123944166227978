import React from "react";
import nosotrosStyle from "../style/nosotros.module.css";
import galeriaStyle from "../style/galeriaStyle.module.css";
import LogoTME from "../views/LogoTME";

const images = [
  {
    img: `${require("../public/galeria3.jpeg")}`,
    alt: "Camion en una contrucción",
  },
  {
    img:`${require("../public/galeria8.jpeg")}`,
    alt:"Grua movilizando una maquina de construcción",
  },
  {
    img:`${require("../public/galeria9.jpeg")}`,
    alt:'Grua movilizando una maquina de construcción'
  },
  {
    img:`${require("../public/galeria14.jpeg")}`,
    alt:'Dos gruas'
  }
  
];

const Galeria = () => {
  return (
    <div className={galeriaStyle.container} id="Galeria">
      <div className={galeriaStyle.conTitleDeco}></div>
      <div className={galeriaStyle.conTitle}>
        <div className={galeriaStyle.datatitle}>
          <div className={nosotrosStyle.subTitle}>
            <div className={nosotrosStyle.decoSubTitle}></div>
            <span style={{ color: "#fff" }}>Nuestros trabajos</span>
          </div>
        </div>
        <div
          className={nosotrosStyle.conTitle}
          style={{ position: "relative" }}
        >
          <h2 style={{ color: "#fff" }}>Galería</h2>

          <LogoTME id={galeriaStyle.logo} />
        </div>
      </div>
      <div className={galeriaStyle.contImages}>
        {images.map((el, index) => (
          <div key={index} className={galeriaStyle.contImage}>
            <img style={{ objectFit: "cover" }} src={el.img} alt={el.alt} />
          </div>
        ))}
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-start", width: "72%" }}
      >
        <a style={{ color: "#fff", textDecoration: "none" }} href="/Galeria">
          <button>Ver Galeria</button>
        </a>
      </div>
    </div>
  );
};

export default Galeria;
