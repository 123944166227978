import React from "react";

const LogoTME = ({ id }) => {
  return (
    <svg
      id={id}
      width="320"
      height="198"
      viewBox="0 0 320 198"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.59824 193.99C9.59824 196.34 7.73824 197.63 5.29824 197.63C3.21824 197.63 1.78824 196.79 0.948242 195.6L2.87824 194.04C3.39824 194.78 4.08824 195.33 5.22824 195.33C6.36824 195.33 7.00824 194.79 7.00824 194.04C7.00824 193.15 6.28824 192.83 5.09824 192.31L4.45824 192.04C2.57824 191.22 1.33824 190.23 1.33824 188.11C1.33824 186.16 2.81824 184.67 5.14824 184.67C6.60824 184.67 7.71824 185.14 8.53824 186.3L6.67824 187.83C6.25824 187.19 5.80824 186.94 5.14824 186.94C4.37824 186.94 3.88824 187.43 3.88824 188.05C3.88824 188.84 4.37824 189.14 5.49824 189.63L6.13824 189.9C8.36824 190.84 9.59824 191.8 9.59824 193.98V193.99Z"
        fill="white"
      />
      <path
        d="M25.6978 191.15C25.6978 194.71 22.8078 197.63 19.2378 197.63C15.6678 197.63 12.7578 194.71 12.7578 191.15C12.7578 187.59 15.6478 184.67 19.2378 184.67C22.8278 184.67 25.6978 187.56 25.6978 191.15ZM23.3678 191.15C23.3678 188.85 21.5078 187.02 19.2378 187.02C16.9678 187.02 15.0778 188.85 15.0778 191.15C15.0778 193.45 16.9378 195.28 19.2378 195.28C21.5378 195.28 23.3678 193.42 23.3678 191.15Z"
        fill="white"
      />
      <path
        d="M38.1687 195.08V197.63H29.3887V184.69H31.9387V195.08H38.1687Z"
        fill="white"
      />
      <path
        d="M52.7685 184.66V192.35C52.7685 195.27 50.3985 197.62 47.4985 197.62C44.5985 197.62 42.2285 195.27 42.2285 192.35V184.66H44.6285V192.35C44.6285 193.93 45.9185 195.22 47.4985 195.22C49.0785 195.22 50.3385 193.93 50.3385 192.35V184.66H52.7585H52.7685Z"
        fill="white"
      />
      <path
        d="M69.2387 194.39C68.0987 196.34 66.0187 197.63 63.6187 197.63C60.0287 197.63 57.1387 194.74 57.1387 191.15C57.1387 187.56 60.0287 184.64 63.6187 184.64C65.9987 184.64 68.0687 185.93 69.1887 187.83L67.2087 189.19C66.5187 187.93 65.1787 187.06 63.6187 187.06C61.3687 187.06 59.5387 188.89 59.5387 191.14C59.5387 193.39 61.3687 195.22 63.6187 195.22C65.1487 195.22 66.4587 194.38 67.1587 193.14L69.2387 194.38V194.39Z"
        fill="white"
      />
      <path d="M72.4082 197.63V184.69H74.9582V197.63H72.4082Z" fill="white" />
      <path
        d="M91.3287 191.15C91.3287 194.71 88.4387 197.63 84.8687 197.63C81.2987 197.63 78.3887 194.71 78.3887 191.15C78.3887 187.59 81.2787 184.67 84.8687 184.67C88.4587 184.67 91.3287 187.56 91.3287 191.15ZM88.9987 191.15C88.9987 188.85 87.1387 187.02 84.8687 187.02C82.5987 187.02 80.7087 188.85 80.7087 191.15C80.7087 193.45 82.5687 195.28 84.8687 195.28C87.1687 195.28 88.9987 193.42 88.9987 191.15Z"
        fill="white"
      />
      <path
        d="M105.779 184.69V197.63H103.229V196.47L97.6086 188.95V197.63H95.0586V184.69H97.6086L103.229 192.21V184.69H105.779Z"
        fill="white"
      />
      <path
        d="M118.498 195.08V197.63H109.568V184.69H118.078V187.24H112.118V191.17H115.908V193.72H112.118V195.08H118.498Z"
        fill="white"
      />
      <path
        d="M130.738 193.99C130.738 196.34 128.878 197.63 126.438 197.63C124.358 197.63 122.928 196.79 122.088 195.6L124.018 194.04C124.538 194.78 125.228 195.33 126.368 195.33C127.508 195.33 128.148 194.79 128.148 194.04C128.148 193.15 127.428 192.83 126.248 192.31L125.608 192.04C123.728 191.22 122.488 190.23 122.488 188.11C122.488 186.16 123.968 184.67 126.298 184.67C127.758 184.67 128.868 185.14 129.688 186.3L127.828 187.83C127.408 187.19 126.958 186.94 126.298 186.94C125.528 186.94 125.038 187.43 125.038 188.05C125.038 188.84 125.528 189.14 126.648 189.63L127.288 189.9C129.518 190.84 130.748 191.8 130.748 193.98L130.738 193.99Z"
        fill="white"
      />
      <path
        d="M153.279 195.08V197.63H144.349V184.69H152.859V187.24H146.899V191.17H150.679V193.72H146.899V195.08H153.279Z"
        fill="white"
      />
      <path
        d="M167.628 184.69V197.63H165.078V196.47L159.458 188.95V197.63H156.908V184.69H159.458L165.078 192.21V184.69H167.628Z"
        fill="white"
      />
      <path
        d="M191.968 187.21H188.178V197.62H185.628V187.21H181.838V184.66H191.958V187.21H191.968Z"
        fill="white"
      />
      <path
        d="M205.798 197.63H203.148L202.158 195.95C201.368 196.37 200.448 196.59 199.488 196.59H198.618V197.63H196.068V184.69H199.478C202.768 184.69 205.438 187.36 205.438 190.63C205.438 192.14 204.868 193.5 203.978 194.54L205.788 197.63H205.798ZM199.488 194.27C201.468 194.27 203.098 192.64 203.098 190.63C203.098 188.62 201.468 187.02 199.488 187.02H198.618V194.27H199.488Z"
        fill="white"
      />
      <path
        d="M222.548 197.63H219.998L219.578 196.59C218.568 197.26 217.348 197.63 216.068 197.63C214.788 197.63 213.568 197.26 212.578 196.59L212.158 197.63H209.608L214.808 184.69H217.358L222.558 197.63H222.548ZM218.668 194.34L216.838 189.79L216.068 187.89L213.498 194.35C214.188 194.92 215.078 195.27 216.068 195.27C217.058 195.27 217.978 194.92 218.668 194.35V194.34Z"
        fill="white"
      />
      <path
        d="M236.649 184.69V197.63H234.099V196.47L228.479 188.95V197.63H225.929V184.69H228.479L234.099 192.21V184.69H236.649Z"
        fill="white"
      />
      <path
        d="M249.088 193.99C249.088 196.34 247.228 197.63 244.788 197.63C242.708 197.63 241.278 196.79 240.438 195.6L242.368 194.04C242.888 194.78 243.578 195.33 244.718 195.33C245.858 195.33 246.498 194.79 246.498 194.04C246.498 193.15 245.778 192.83 244.588 192.31L243.948 192.04C242.068 191.22 240.828 190.23 240.828 188.11C240.828 186.16 242.308 184.67 244.638 184.67C246.098 184.67 247.208 185.14 248.028 186.3L246.168 187.83C245.748 187.19 245.298 186.94 244.638 186.94C243.868 186.94 243.378 187.43 243.378 188.05C243.378 188.84 243.868 189.14 244.988 189.63L245.628 189.9C247.858 190.84 249.088 191.8 249.088 193.98V193.99Z"
        fill="white"
      />
      <path
        d="M262.028 190.63C262.028 193.92 259.358 196.59 256.088 196.59H254.878V197.63H252.328V184.69H256.088C259.348 184.69 262.028 187.36 262.028 190.63ZM259.698 190.63C259.698 188.65 258.068 187.02 256.088 187.02H254.878V194.27H256.088C258.068 194.27 259.698 192.64 259.698 190.63Z"
        fill="white"
      />
      <path
        d="M278.749 191.15C278.749 194.71 275.859 197.63 272.289 197.63C268.719 197.63 265.809 194.71 265.809 191.15C265.809 187.59 268.699 184.67 272.289 184.67C275.879 184.67 278.749 187.56 278.749 191.15ZM276.419 191.15C276.419 188.85 274.559 187.02 272.289 187.02C270.019 187.02 268.129 188.85 268.129 191.15C268.129 193.45 269.989 195.28 272.289 195.28C274.589 195.28 276.419 193.42 276.419 191.15Z"
        fill="white"
      />
      <path
        d="M292.159 197.63H289.509L288.519 195.95C287.729 196.37 286.809 196.59 285.849 196.59H284.979V197.63H282.429V184.69H285.839C289.129 184.69 291.799 187.36 291.799 190.63C291.799 192.14 291.229 193.5 290.339 194.54L292.149 197.63H292.159ZM285.849 194.27C287.829 194.27 289.459 192.64 289.459 190.63C289.459 188.62 287.829 187.02 285.849 187.02H284.979V194.27H285.849Z"
        fill="white"
      />
      <path
        d="M306.088 187.21H302.298V197.62H299.748V187.21H295.958V184.66H306.078V187.21H306.088Z"
        fill="white"
      />
      <path
        d="M319.128 195.08V197.63H310.198V184.69H318.708V187.24H312.748V191.17H316.538V193.72H312.748V195.08H319.128Z"
        fill="white"
      />
      <path
        d="M171.339 106.24V106.28H171.309C171.309 106.28 171.309 106.26 171.339 106.23V106.24Z"
        fill="white"
      />
      <path
        d="M47.799 175.26V68.17H0.379016C0.0490157 65.23 -0.120985 61.73 0.099015 57.81C0.349015 53.43 1.03902 49.61 1.80902 46.5C107.419 46.41 213.029 46.31 318.639 46.22V68.17H77.749V175.26H47.799Z"
        fill="white"
      />
      <path
        d="M47.3989 68.38H0.418945C3.37895 94.29 22.4689 115.28 47.4089 121.07V68.38H47.3989Z"
        fill="white"
        fillOpacity="0.28"
      />
      <path
        d="M120.689 45.8C113.809 19.45 89.849 0 61.339 0C32.829 0 8.80898 19.5 1.95898 45.9L120.689 45.8Z"
        fill="white"
        fillOpacity="0.28"
      />
      <path
        d="M78.1494 68.38V120.34C83.9994 118.67 89.4994 116.16 94.4994 112.94C94.4994 99.32 94.4994 85.7 94.4994 72.08H94.5894C103.639 72.08 112.679 72.08 121.729 72.09C121.949 70.87 122.109 69.63 122.259 68.38H78.1494Z"
        fill="white"
        fillOpacity="0.28"
      />
      <path
        d="M264.918 73.6C260.668 74.09 249.688 75.7 231.838 83.84C227.578 85.78 221.678 88.67 214.838 92.68C208.148 96.79 199.098 102.91 189.168 111.56C164.788 131.45 145.198 155.66 138.908 175.81C150.218 175.85 161.518 175.88 172.828 175.92C168.468 175.71 164.108 175.5 159.748 175.29C160.998 172.16 162.258 169.03 163.508 165.91L174.328 166.2L172.838 175.93L198.878 176.01C200.548 166.81 206.768 136.32 225.398 111.08C239.318 92.22 255.958 81.99 258.368 80.68C262.048 78.67 264.148 77.75 264.148 77.75C269.618 75.36 270.628 74.97 270.628 74.97C272.268 74.45 274.168 73.77 274.088 73.38C273.938 72.59 265.668 73.52 264.928 73.61L264.918 73.6ZM177.598 159.53C174.748 159.47 170.928 158.97 168.218 158.32C171.488 152.09 171.958 142.49 181.968 146.86C180.508 151.08 179.058 155.31 177.598 159.53ZM189.728 132.59C188.858 133.34 185.068 143.48 183.168 142.48C182.008 141.87 176.548 141.74 178.078 139.36C178.898 138.77 184.228 129.27 185.698 130.11C186.888 130.51 190.778 130.46 189.728 132.59ZM198.728 119.61L193.438 126.79C192.498 128.17 190.738 127.07 189.648 126.52C186.368 125.13 193.928 118.49 194.388 117C195.718 115.22 200.228 117.61 198.728 119.62V119.61ZM210.098 106.47L203.078 112.35C202.358 112.99 201.458 112.45 200.918 111.88C198.638 110.36 207.818 105.47 208.338 104.53C209.538 103.59 211.318 105.48 210.098 106.47ZM217.508 100.92C216.358 101.29 216.378 99.15 217.288 98.81L225.088 94C227.828 94.77 223.508 97.13 217.518 100.93L217.508 100.92Z"
        fill="white"
      />
      <path
        d="M159.738 175.28H172.928L172.828 175.92L159.738 175.81V175.28Z"
        fill="white"
      />
      <path
        d="M192.689 72.33L156.699 124.17L121.709 72.33C112.729 72.3 103.749 72.27 94.7688 72.23C94.7488 106.82 94.7387 141.41 94.7188 176H122.099V115.72L146.949 151.56H149.919L163.589 134.37C171.599 125.52 181.599 115.76 193.819 106.11C202.649 99.14 211.209 93.36 219.109 88.58V72.33H192.679H192.689Z"
        fill="white"
      />
      <path
        d="M199.899 175.92C201.529 167.79 203.919 158.52 207.459 148.51C211.089 138.27 215.169 129.39 219.109 121.93V176.01C212.699 175.98 206.299 175.95 199.889 175.93L199.899 175.92Z"
        fill="white"
      />
      <path
        d="M318.719 152.94V175.26H235.959V99.65C240.319 95.04 246.329 89.52 254.219 84.31C262.539 78.81 270.379 75.35 276.549 73.14H319.089V95.32H262.199V113.12H311.709V133.4H262.199V152.95H318.719V152.94Z"
        fill="white"
      />
      <path
        d="M262.268 73.14H235.948V80.43C239.628 78.82 243.968 77.2 248.928 75.83C253.818 74.48 258.328 73.65 262.268 73.14Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoTME;
