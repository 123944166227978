import React, { useState } from "react";
import LogoTME from "../views/LogoTME2";

const Header = () => {
  const [menuActive, setMenuActive] = useState(false);
  const handleClick = () => setMenuActive(!menuActive);
  return (
    <header
      style={{
        backgroundColor: "#264897",
        height: 100,
        margin: 0,
        padding: 35,
      }}
    >
      <LogoTME id="Capa_3" />
      <svg viewBox="0 0 1280 138" fill="none" className="svgHeader">
        <path
          d="M0 132C21.1675 105.667 153.783 55.4286 282.319 64C357.298 69 511.081 38.0001 583 0"
          stroke="white"
          strokeOpacity="0.15"
          strokeWidth="3"
        />
        <path
          d="M697 137C718.168 110.667 850.783 60.4286 979.319 69C1054.3 74 1208.08 43.0001 1280 5"
          stroke="white"
          strokeOpacity="0.15"
          strokeWidth="3"
        />
      </svg>

      <div
        onClick={handleClick}
        className={`HamburgerMenu1 ${
          menuActive ? "HamburgerMenuActive" : "HamburgerMenuDisabled"
        }`}
      >
        <div className="firtLineMenu"></div>
        <div className="SecodLineMenu"></div>
        <div className="thirthLineMenu"></div>
      </div>
      <nav
        style={{margin: 0}}
        className={`navMenu ${menuActive ? "MenuActive" : "MenuDisabled"}`}
      >
        <ul>
          <li>
            <a style={{ color: "#fff", textDecoration: "none" }} href="/#home">
              Inicio
            </a>
          </li>
          <li>
            <a
              style={{ color: "#fff", textDecoration: "none" }}
              href="/Galeria"
            >
              Galeria
            </a>
          </li>
          <li>
            <a
              style={{ color: "#fff", textDecoration: "none" }}
              href="/#Contacto"
            >
              Contacto
            </a>
          </li>
          <li>
            <a
              style={{ color: "#fff", textDecoration: "none" }}
              href="/#Nosotros"
            >
              Nosotros
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
