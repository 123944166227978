import React from "react";
import styles from "../style/services.module.css";
import Carry from "../views/Carry";
import Furgon from "../views/Furgon";
import Grua from "../views/Grua";
import Camion from "../views/Camion";
import { defautlVariables } from "../const";
import { useNavigate } from "react-router-dom";
import Avion from "../views/Avion";

const Services = () => {
  const navigate = useNavigate();

  const goContacto = (value) => {
    console.log();
    const data = defautlVariables;
    data.tipoDeCarga = value;
    const result = JSON.stringify(data);
    sessionStorage.setItem("DatosContacto", result);
    navigate("/Contacto");
  };

  return (
    <div className={styles.container}>
      <div className={styles.deco}>
        <svg
          width="1280"
          height="507"
          viewBox="0 0 1280 507"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 332C0 332 100.011 484.244 201.5 503C333.441 527.384 365.326 332.483 499.5 332C634.522 331.514 665.386 513.504 800 503C958.871 490.603 973.547 324.863 1084 210C1164.89 125.882 1281 2 1281 2"
            stroke="#69B32D"
            strokeWidth="3"
          />
          <path
            d="M383.76 408.232C379.48 411.722 373.18 411.081 369.69 406.801L366.32 402.668C362.83 398.388 363.47 392.089 367.75 388.599L369.369 387.278L376.534 381.436L404.882 358.32C409.162 354.829 415.462 355.47 418.952 359.75L422.322 363.883C425.812 368.163 425.172 374.463 420.892 377.953L383.76 408.232Z"
            fill="#264897"
          />
          <path
            d="M1204.9 101.995C1200.91 105.815 1194.58 105.679 1190.76 101.69L1184.53 95.1871C1180.71 91.1985 1180.85 84.8684 1184.84 81.0483L1189.7 76.3934L1198.94 67.5444L1238.27 29.8761C1242.26 26.056 1248.59 26.1926 1252.41 30.1812L1258.63 36.6843C1262.45 40.6729 1262.32 47.0031 1258.33 50.8232L1204.9 101.995Z"
            fill="#264897"
          />
        </svg>
      </div>
      <div>
        <div className={styles.contSubtitle}>
          <div className={styles.decoSubtitle}></div>
          <span>¿Cuáles son nuestros servicios?</span>
        </div>
        <div>
          <h2>Nuestros Servicios</h2>
        </div>
      </div>
      <div className={styles.contCardServices}>
        <div className={styles.contImageCard} id={styles.service1}>
          <span></span>
          <Carry />
        </div>
        <div>
          <h3>Carga seca</h3>
        </div>
        <div>
          <p>
            Eficiente transporte de carga seca para tus productos. Soluciones
            logísticas confiables que aseguran la entrega oportuna y segura de
            tus mercancías.
          </p>
          <p> Confía en nosotros para optimizar tu cadena de suministro.</p>
        </div>
        <div>
          <button
            onClick={() => {
              goContacto(1);
            }}
            style={{ backgroundColor: "#69B32D" }}
          >
            <span>Solicitar</span>
          </button>
        </div>
      </div>
      <div className={styles.contCardServices}>
        <div className={styles.contImageCard} id={styles.service2}>
          <span></span>
          <Furgon />
        </div>
        <div>
          <h3>Carga refrigerada</h3>
        </div>
        <div>
          <p>Transporte de productos sensibles con control de temperatura. </p>
          <p>Garantizamos frescura y calidad en cada entrega.</p>
        </div>
        <div>
          <button
            onClick={() => {
              goContacto(2);
            }}
            style={{ backgroundColor: "#264897" }}
          >
            <span>Solicitar</span>
          </button>
        </div>
      </div>

      <div className={styles.contCardServices}>
        <div className={styles.contImageCard} id={styles.service3}>
          <span></span>
          <Grua />
        </div>
        <div>
          <h3>Gruas</h3>
        </div>
        <div>
          <p>
            Elevamos tu confianza con nuestro servicio de grúa. Profesionales en
            movimiento, listos para asistirte en cualquier situación.{" "}
          </p>
          <p>Tu carga, segura y en manos expertas.</p>
        </div>
        <div>
          <button
            onClick={() => {
              goContacto(3);
            }}
            style={{ backgroundColor: "#69B32D" }}
          >
            <span>Solicitar</span>
          </button>
        </div>
      </div>
      <div className={styles.contCardServices}>
        <div className={styles.contImageCard} id={styles.service4}>
          <span></span>
          <Camion />
        </div>
        <div>
          <h3>Maquinaria y equipos </h3>
        </div>
        <div>
          <p>
            Mover maquinaria y equipos con precisión. Nuestra experiencia en
            transporte garantiza la entrega segura y puntual de tus activos.{" "}
          </p>
          <p>
            Confía en nosotros para manejar tus necesidades logísticas
            especializadas y mantener tus operaciones en marcha.
          </p>
        </div>
        <div>
          <button
            onClick={() => {
              goContacto(4);
            }}
            style={{ backgroundColor: "#264897" }}
          >
            <span>Solicitar</span>
          </button>
        </div>
      </div>
      <div className={styles.contCardServices}>
        <div className={styles.contImageCard} id={styles.service5}>
          <span></span>
          <Avion id={styles.avion} />
        </div>
        <div>
          <h3>Trasporte Aereo</h3>
        </div>
        <div>
          <p>
            Veloz transporte aéreo a tu servicio. Confiabilidad y rapidez en la
            entrega de tus productos por los cielos.{" "}
          </p>
          <p>
            Optimiza tus plazos de entrega con nuestra experiencia en logística
            aérea. Tu carga, en buenas manos voladoras.
          </p>
        </div>
        <div>
          <button
            onClick={() => {
              goContacto(5);
            }}
            style={{ backgroundColor: "#69B32D" }}
          >
            <span>Solicitar</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Services;

/*

const [sizeContainer, setSizeContainer] = useState({ width: 0, height: 0 });
  const container = useRef();
  const canvas = useRef();
  const cargaSeca = useRef();
  const cargaRefrigerada = useRef();
  const gruas = useRef();
  const trasporteAereo = useRef();
  const MaquinariaYEquipos = useRef();

  useEffect(() => {
    function updateLinePosition() {
      const ctx = canvas.current.getContext("2d");
      ctx.beginPath();

      //context.quadraticCurveTo(cp1x, cp1y, x, y);
      //parametros de quadraticCurveTo
      // el tercer parametro es el punto de la izquierda
      ctx.stroke();
      const rect1 = cargaSeca.current.getBoundingClientRect();
      const rect2 = cargaRefrigerada.current.getBoundingClientRect();

      const x1 = rect1.left + 105;
      const y1 = rect1.top / 2 + window.scrollY;

      const x2 = rect2.left + rect2.width / 2 + window.scrollX;
      const y2 = rect2.top + rect2.height / 2 + window.scrollY;

      //console.log('x2',x2,y2,'x1',x1,y1)

      const coordenadas = [
        { x: x1, y: y1 },
        { x: x2, y: y2 },
      ];

      console.log(x1, y1);
      ctx.moveTo(0, 0);
      //ctx.quadraticCurveTo(200,950, 450, 450);

      ctx.quadraticCurveTo(200, 950, x1, 450);
      //ctx.quadraticCurveTo(550,200, 820, 600);
      //ctx.quadraticCurveTo(900,600,1200, 260);

      //ctx.moveTo(coordenadas[0].x, coordenadas[0].y);
      //ctx.quadraticCurveTo(coordenadas[0].x, coordenadas[0].y, 60, 30);

      
        ctx.quadraticCurveTo(200,950, 450, 450);
        ctx.quadraticCurveTo(550,200, 820, 600);
        ctx.quadraticCurveTo(900,600,1200, 260);
      

      for (let i = 1; i < coordenadas.length - 2; i++) {

        const xc = (coordenadas[i].x + coordenadas[i + 1].x) ;
        const yc = (coordenadas[i].y + coordenadas[i + 1].y) ;
        ctx.quadraticCurveTo(coordenadas[i].x, coordenadas[i].y, xc, yc);

        console.log('cx' + coordenadas[i].x, 'cy',coordenadas[i].y,'xc: '+ xc , 'xy: ' + yc)
      }
  
      // Dibuja la última línea recta
      ctx.quadraticCurveTo(
        coordenadas[coordenadas.length - 2].x,
        coordenadas[coordenadas.length - 2].y,
        coordenadas[coordenadas.length - 1].x,
        coordenadas[coordenadas.length - 1].y
      );

      ctx.strokeStyle = "blue";
      ctx.lineWidth = 4;
      ctx.stroke();
    }

    // updateLinePosition();
    // window.addEventListener("resize", updateLinePosition);
    // window.addEventListener("scroll", updateLinePosition);

    // return () => {
    //   window.removeEventListener("resize", updateLinePosition);
    //   window.removeEventListener("scroll", updateLinePosition);
    // };
  }, []);

  useEffect(() => {
    const ctx = canvas.current.getContext("2d");
    const coordCargaSeca = cargaSeca.current.getBoundingClientRect();
    const coordCargaRefrigerada =cargaRefrigerada.current.getBoundingClientRect()
    console.log('coordCargaSeca',coordCargaSeca)

    if (container.current) {
      const width = container.current.offsetWidth;
      const height = container.current.offsetHeight;
      setSizeContainer({ width, height });
    }

    const coordenadas = [
      { x: 0, y: 200 },
      { x: coordCargaSeca.left  , y: coordCargaSeca.top },
      { x:coordCargaSeca.left+70,y:coordCargaSeca.top-50},
      { x: coordCargaRefrigerada.left, y: coordCargaRefrigerada.top },
    ];

    ctx.beginPath();
    ctx.moveTo(coordenadas[0].x, coordenadas[0].y);

    for (let i = 1; i < coordenadas.length - 2; i++) {
      const xc = (coordenadas[i].x + coordenadas[i + 1].x) / 2;
      const yc = (coordenadas[i].y + coordenadas[i + 1].y) / 2;
      ctx.quadraticCurveTo(coordenadas[i].x, coordenadas[i].y, xc, yc);
    }

    // Dibuja la última línea recta
    ctx.quadraticCurveTo(
      coordenadas[coordenadas.length - 2].x,
      coordenadas[coordenadas.length - 2].y,
      coordenadas[coordenadas.length - 1].x,
      coordenadas[coordenadas.length - 1].y
    );

    ctx.strokeStyle = "blue";
    ctx.lineWidth = 4;
    ctx.stroke();

  }, [sizeContainer.width]);


<div className={styles.container} ref={container}>
      <div className={styles.svgDeco}>
        <svg xmlns="http://www.w3.org/2000/svg">
          <circle cx="220" cy="320" r="10" fill="blue" />
          <circle cx="570" cy="580" r="10" fill="blue" />
          <circle cx="220" cy="910" r="10" fill="blue" />
          <circle cx="570" cy="1200" r="10" fill="blue" />
          <circle cx="220" cy="1420" r="10" fill="blue" />
          {container?.current ? (
            container.current.offsetWidth > 1200 ? (
              <path
                d={`M -10 300 C 0 450, 50 500, 240 550, 
                            340 550, 340 400, 550 380, 
                            740 380, 670 580, 890 610,
                            1100 560, 970 470, 1240,270 `}
                stroke="#69B32D"
                fill="none"
                strokeWidth={4}
              />
            ) : container.current.offsetWidth <= 1200 &&
              container.current.offsetWidth > 600 ? (
              <path
                d={`M 0 30 C 0 30, 30 300, 220 320, 
                            220 320, 1100 380, 570 580,
                            500 620, -220 750, 220 910 
                            250 940, 1100 1000, 570 1200,
                            400 1250, 340 1250, 220 1420`}
                stroke="#69B32D"
                fill="none"
                strokeWidth={4}
              />
            ) : (
              <path
                d={`M -10 300 C 0 450, 50 500, 240 550, 
                            340 550, 340 400, 550 380, 
                            740 380, 670 580, 890 610,
                            1100 560, 970 470, 1240,270 `}
                stroke="#69B32D"
                fill="none"
                strokeWidth={4}
              />
            )
          ) : null}
        </svg>
      </div>

      <div className={styles.deco}></div>
      <div className={styles.containerTitle}>
        <div className={styles.title}>
          <div>
            <span></span>
            <p>¿Cuáles son nuestros servicios?</p>
          </div>
          <h2>Nuestros Servicios</h2>
        </div>
        <div
          id={styles.cargaSeca}
          className={`${styles.contServises} ${styles.contServisesDown}`}
        >
          <div>
            <div ref={cargaSeca} className={styles.contImageService}>
              <Carry />
            </div>
            <div>
              <h3>Carga seca</h3>
              <p>
                Eficiente transporte de carga seca para tus productos.
                Soluciones logísticas confiables que aseguran la entrega
                oportuna y segura de tus mercancías. Confía en nosotros para
                optimizar tu cadena de suministro.
              </p>
            </div>
          </div>
        </div>
        <div
          id={styles.cargaRefrigerada}
          className={`${styles.contServises} ${styles.contServisesUp}`}
        >
          <div>
            <div ref={cargaRefrigerada} className={styles.contImageService}>
              <Furgon />
            </div>
            <div>
              <h3>Carga refrigerada</h3>
              <p>
                Transporte de productos sensibles con control de temperatura.
                Garantizamos frescura y calidad en cada entrega.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contFullServises}>
        <div className={`${styles.contServises} ${styles.contServisesUp}`}>
          <div>
            <div ref={trasporteAereo} className={styles.contImageService}>
              <Furgon />
            </div>
            <div>
              <h3>Trasporte Aereo</h3>
              <p>
                Veloz transporte aéreo a tu servicio. Confiabilidad y rapidez en
                la entrega de tus productos por los cielos. Optimiza tus plazos
                de entrega con nuestra experiencia en logística aérea. Tu carga,
                en buenas manos voladoras.
              </p>
            </div>
          </div>
        </div>
        <div className={`${styles.contServises} ${styles.contServisesCenter}`}>
          <div>
            <div ref={MaquinariaYEquipos} className={styles.contImageService}>
              <Camion />
            </div>
            <div>
              <h3>Maquinaria y equipos </h3>
              <p>
                Mover maquinaria y equipos con precisión. Nuestra experiencia en
                transporte garantiza la entrega segura y puntual de tus activos.
                Confía en nosotros para manejar tus necesidades logísticas
                especializadas y mantener tus operaciones en marcha.
              </p>
            </div>
          </div>
        </div>
        <div className={`${styles.contServises} ${styles.contServisesUp}`}>
          <div>
            <div ref={gruas} className={styles.contImageService}>
              <Grua />
            </div>
            <div>
              <h3>Gruas</h3>
              <p>
                Elevamos tu confianza con nuestro servicio de grúa.
                Profesionales en movimiento, listos para asistirte en cualquier
                situación. Tu carga, segura y en manos expertas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
*/
