import { useRef } from "react";
import nosotrosStyle from "../style/nosotros.module.css";

const SobreNosotros = () => {
  const slideShow = useRef(null);

  const siguiente = () => {
    if (slideShow.current.children.length > 0) {
      const firstElement = slideShow.current.children[0];

      slideShow.current.style.transition = `400ms ease-out all`;
      const tamañoSlide = slideShow.current.children[0].offsetWidth;

      slideShow.current.style.transform = `translateX(-${tamañoSlide}px)`;

      const transition = () => {
        slideShow.current.style.transition = "none";
        slideShow.current.style.transform = `translateX(0)`;

        slideShow.current.appendChild(firstElement);

        slideShow.current.removeEventListener('transitionend',transition)
      };

      slideShow.current.addEventListener("transitionend", transition);
    }
  };

  const anterior = () => {
    if (slideShow.current.children.length > 0) {
      const indexUltimoELemento = slideShow.current.children.length - 1;
      const ultimoElemento = slideShow.current.children[indexUltimoELemento];
      const tamañoSlide = slideShow.current.children[0].offsetWidth;

      slideShow.current.insertBefore(
        ultimoElemento,
        slideShow.current.firstChild
      );

      slideShow.current.style.transition = "none";
      slideShow.current.style.transform = `translateX(-${tamañoSlide}px)`;

      setTimeout(()=>{
        slideShow.current.style.transition = `400ms ease-out all`;
        slideShow.current.style.transform = `translateX(0)`;
      },30)

      
    }
  };

  return (
    <div className={nosotrosStyle.container} id="Nosotros">
      <div className={nosotrosStyle.contText}>
        <div className={nosotrosStyle.subTitle}>
          <div className={nosotrosStyle.decoSubTitle}></div>
          <span>¿Nuestra trayectoria?</span>
        </div>
        <div className={nosotrosStyle.conTitle}>
          <h2>
            Sobre <br /> Nosotros
          </h2>
        </div>
        <div className={nosotrosStyle.contParrafos}>
          <p>
            Somos una nueva pero experimentada figura en el mundo logístico.
            Combinamos juventud con conocimiento profundo y adoptamos
            tecnologías de vanguardia para mejorar la eficiencia.{" "}
          </p>
          <p>
            Nuestra pasión radica en brindar soluciones personalizadas que se
            ajusten a tus necesidades, asegurando resultados excepcionales en
            cada paso. Tu éxito es nuestra motivación constante mientras
            avanzamos juntos hacia un futuro logístico brillante.
          </p>
        </div>
      </div>
      <div className={nosotrosStyle.contImage}>
        <div className={nosotrosStyle.contBtn}>
          <div onClick={anterior}>
            <svg
              width="13"
              height="20"
              viewBox="0 0 13 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.543963 11.1818C-0.181321 10.531 -0.181321 9.47416 0.543963 8.82337L0.53816 8.81817L9.8218 0.488091C10.5471 -0.162697 11.7249 -0.162697 12.4502 0.488091C13.1755 1.13888 13.1755 2.19576 12.4502 2.84654L6.34044 8.33398L4.78543 10L6.33464 11.666L12.456 17.1535C13.1813 17.8042 13.1813 18.8611 12.456 19.5119C11.7308 20.1627 10.5529 20.1627 9.8276 19.5119L0.543963 11.1818Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.543963 11.1818C-0.181321 10.531 -0.181321 9.47416 0.543963 8.82337L0.53816 8.81817L9.8218 0.488091C10.5471 -0.162697 11.7249 -0.162697 12.4502 0.488091C13.1755 1.13888 13.1755 2.19576 12.4502 2.84654L6.34044 8.33398L4.78543 10L6.33464 11.666L12.456 17.1535C13.1813 17.8042 13.1813 18.8611 12.456 19.5119C11.7308 20.1627 10.5529 20.1627 9.8276 19.5119L0.543963 11.1818Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.543963 11.1818C-0.181321 10.531 -0.181321 9.47416 0.543963 8.82337L0.53816 8.81817L9.8218 0.488091C10.5471 -0.162697 11.7249 -0.162697 12.4502 0.488091C13.1755 1.13888 13.1755 2.19576 12.4502 2.84654L6.34044 8.33398L4.78543 10L6.33464 11.666L12.456 17.1535C13.1813 17.8042 13.1813 18.8611 12.456 19.5119C11.7308 20.1627 10.5529 20.1627 9.8276 19.5119L0.543963 11.1818Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.543963 11.1818C-0.181321 10.531 -0.181321 9.47416 0.543963 8.82337L0.53816 8.81817L9.8218 0.488091C10.5471 -0.162697 11.7249 -0.162697 12.4502 0.488091C13.1755 1.13888 13.1755 2.19576 12.4502 2.84654L6.34044 8.33398L4.78543 10L6.33464 11.666L12.456 17.1535C13.1813 17.8042 13.1813 18.8611 12.456 19.5119C11.7308 20.1627 10.5529 20.1627 9.8276 19.5119L0.543963 11.1818Z"
                fill="white"
              />
            </svg>
          </div>
          <div onClick={siguiente}>
            <svg
              width="13"
              height="20"
              viewBox="0 0 13 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.456 8.81817C13.1813 9.46896 13.1813 10.5258 12.456 11.1766L12.4618 11.1818L3.1782 19.5119C2.45291 20.1627 1.27505 20.1627 0.549766 19.5119C-0.175518 18.8611 -0.175518 17.8042 0.549766 17.1535L6.65956 11.666L8.21457 10L6.66536 8.33399L0.543963 2.84655C-0.181321 2.19576 -0.181321 1.13888 0.543963 0.488091C1.26925 -0.162697 2.44711 -0.162697 3.1724 0.488091L12.456 8.81817Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.456 8.81817C13.1813 9.46896 13.1813 10.5258 12.456 11.1766L12.4618 11.1818L3.1782 19.5119C2.45291 20.1627 1.27505 20.1627 0.549766 19.5119C-0.175518 18.8611 -0.175518 17.8042 0.549766 17.1535L6.65956 11.666L8.21457 10L6.66536 8.33399L0.543963 2.84655C-0.181321 2.19576 -0.181321 1.13888 0.543963 0.488091C1.26925 -0.162697 2.44711 -0.162697 3.1724 0.488091L12.456 8.81817Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.456 8.81817C13.1813 9.46896 13.1813 10.5258 12.456 11.1766L12.4618 11.1818L3.1782 19.5119C2.45291 20.1627 1.27505 20.1627 0.549766 19.5119C-0.175518 18.8611 -0.175518 17.8042 0.549766 17.1535L6.65956 11.666L8.21457 10L6.66536 8.33399L0.543963 2.84655C-0.181321 2.19576 -0.181321 1.13888 0.543963 0.488091C1.26925 -0.162697 2.44711 -0.162697 3.1724 0.488091L12.456 8.81817Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.456 8.81817C13.1813 9.46896 13.1813 10.5258 12.456 11.1766L12.4618 11.1818L3.1782 19.5119C2.45291 20.1627 1.27505 20.1627 0.549766 19.5119C-0.175518 18.8611 -0.175518 17.8042 0.549766 17.1535L6.65956 11.666L8.21457 10L6.66536 8.33399L0.543963 2.84655C-0.181321 2.19576 -0.181321 1.13888 0.543963 0.488091C1.26925 -0.162697 2.44711 -0.162697 3.1724 0.488091L12.456 8.81817Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <div className={nosotrosStyle.contenedorPrincipal}>
          <div ref={slideShow} className={nosotrosStyle.contenedorSlideShow}>

            <div className={nosotrosStyle.slide}>
              <img src={require("../public/portada.png")} alt={'tres carrys y un furgon'} />
            </div>
            <div className={nosotrosStyle.slide}>
              <img src={require("../public/portada2.jpeg")} alt={'carry con globos'} />
            </div>
            <div className={nosotrosStyle.slide}>
              <img src={require("../public/portada3.jpeg")} alt={'dos furgones, dos carrys de carga y un carro'} />
            </div>
            <div className={nosotrosStyle.slide}>
              <img src={require("../public/portada4.jpeg")} alt={'dos furgones, dos carrys de carga y dos carro'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/*
<div className={nosotrosStyle.ContainerViwerImage}>
          <div ref={slideShow}>
            <div>
              <img src={require("../public/portada.png")} />
            </div>
            <div>
              <img src={require("../public/portada2.jpeg")} />
            </div>
            <div>
              <img src={require("../public/portada3.jpeg")} />
            </div>
            <div>
              <img src={require("../public/portada4.jpeg")} />
            </div>
          </div>
        </div>


<img src={require("../public/galery1.png")} />
<div ref={slideShow}>
          <img src={require("../public/portada.png")} />
          <img src={require("../public/portada2.jpeg")} />
          <img src={require("../public/portada3.jpeg")} />
          <img src={require("../public/portada4.jpeg")} />
        </div>
*/
export default SobreNosotros;
