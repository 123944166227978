import { useState } from "react";
import Header from "./Header";
import styles from "../style/galeriaCompleta.module.css";
import img1 from "../public/home1.jpeg";
import img2 from "../public/galeria1.jpeg";
import img3 from "../public/galeria4.jpeg";
import img4 from "../public/galeria5.jpeg";
import img5 from "../public/galeria6.jpeg";
import img6 from "../public/galeria3.jpeg";
import img7 from "../public/galeria7.jpeg";
import img8 from "../public/galeria8.jpeg";
import img9 from "../public/galeria9.jpeg";
import img10 from "../public/galeria10.jpeg";
import img11 from "../public/galeria11.jpeg";
import img12 from "../public/galeria12.jpeg";
import img13 from "../public/galeria13.jpeg";
import img14 from "../public/galeria14.jpeg";
import img15 from "../public/galeria15.jpeg";
import img16 from "../public/galeria16.jpeg";
import img17 from "../public/galeria17.jpeg";
import img18 from "../public/galeria18.jpeg";
import img19 from "../public/galeria19.jpeg";
import img20 from "../public/galeria20.jpg";
import img21 from "../public/galeria21.jpeg";
import img22 from "../public/galeria22.jpeg";
import img23 from "../public/galeria23.jpg";
import img24 from "../public/galeria24.jpg";
import img25 from "../public/galeria25.jpg";
import img26 from "../public/galeria26.jpg";
import img27 from "../public/galeria27.jpg";
import img28 from "../public/galeria28.jpg";
import img29 from "../public/galeria29.jpeg";
import img30 from "../public/galeria30.jpeg";
import img32 from "../public/galeria32.jpeg";
import Footer from "./Footer";

let data = [
  {
    id: 1,
    imgSource: img1,
    alt:'gruas y camiones'
  },
  {
    id: 2,
    imgSource: img2,
    alt:'gruas y camiones'
  },
  {
    id: 3,
    imgSource: img3,
    alt:'gruas y camiones'
  },
  {
    id: 4,
    imgSource: img4,
    alt:'gruas y camiones'
  },
  {
    id: 5,
    imgSource: img5,
    alt:'gruas y camiones'
  },
  {
    id: 6,
    imgSource: img6,
    alt:'gruas y camiones'
  },
  {
    id: 7,
    imgSource: img7,
    alt:'gruas y camiones'
  },
  {
    id: 8,
    imgSource: img8,
    alt:'gruas y camiones'
  },
  {
    id: 9,
    imgSource: img9,
    alt:'gruas y camiones'
  },
  {
    id: 10,
    imgSource: img10,
    alt:'gruas y camiones'
  },
  {
    id: 11,
    imgSource: img11,
    alt:'gruas y camiones'
  },
  {
    id: 12,
    imgSource: img12,
    alt:'gruas y camiones'
  },
  {
    id: 13,
    imgSource: img13,
    alt:'gruas y camiones'
  },
  {
    id: 14,
    imgSource: img14,
    alt:'gruas y camiones'
  },
  {
    id: 15,
    imgSource: img15,
    alt:'gruas y camiones'
  },
  {
    id: 16,
    imgSource: img16,
    alt:'gruas y camiones'
  },
  {
    id: 17,
    imgSource: img17,
    alt:'gruas y camiones'
  },
  {
    id: 18,
    imgSource: img18,
    alt:'gruas y camiones'
  },
  {
    id: 19,
    imgSource: img19,
    alt:'gruas y camiones'
  },
  {
    id: 20,
    imgSource: img20,
    alt:'gruas y camiones'
  },
  {
    id: 21,
    imgSource: img21,
    alt:'gruas y camiones'
  },
  {
    id: 22,
    imgSource: img22,
    alt:'gruas y camiones'
  },
  {
    id: 23,
    imgSource: img23,
    alt:'gruas y camiones'
  },
  {
    id: 24,
    imgSource: img24,
    alt:'gruas y camiones'
  },
  {
    id: 25,
    imgSource: img25,
    alt:'gruas y camiones'
  },
  {
    id: 26,
    imgSource: img26,
    alt:'gruas y camiones'
  },
  {
    id: 27,
    imgSource: img27,
    alt:'gruas y camiones'
  },
  {
    id: 28,
    imgSource: img28,
    alt:'gruas y camiones'
  },
  {
    id: 29,
    imgSource: img29,
    alt:'gruas y camiones'
  },
  {
    id: 30,
    imgSource: img30,
    alt:'gruas y camiones'
  },
  {
    id: 32,
    imgSource: img32,
    alt:'gruas y camiones'
  },
];

const GaleriaCompleta = () => {
  const [model, setModel] = useState(false);
  const [tempingSrc, setTempingSrc] = useState("");
  const [altTemporal,setAltTemporal] = useState('')

  const getImg = (imgSource,alt) => {
    setTempingSrc(imgSource);
    setAltTemporal(alt)
    setModel(true);
  };

  const closeModal = () => {
    setTempingSrc("");
    setModel(false);
  };

  return (
    <div>
      <div className="contWhatsBoton">
        <a href="/Contacto">
          <svg
            width="77"
            height="77"
            viewBox="0 0 77 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              id="circleWhats"
              width="77"
              height="77"
              rx="38.5"
              fill="#1FB82E"
            />
            <path
              d="M54.9605 24.1031C50.5647 20.1656 44.7107 18 38.4895 18C25.6484 18 15.1993 27.3375 15.1993 38.8125C15.1993 42.4781 16.2694 46.0594 18.3047 49.2188L15 60L27.348 57.1031C30.7471 58.7625 34.5763 59.6344 38.479 59.6344H38.4895C51.3201 59.6344 62 50.2969 62 38.8219C62 33.2625 59.3563 28.0406 54.9605 24.1031ZM38.4895 56.1281C35.0065 56.1281 31.5969 55.2938 28.6279 53.7188L27.925 53.3438L20.6022 55.0594L22.5536 48.675L22.092 48.0188C20.1511 45.2625 19.1335 42.0844 19.1335 38.8125C19.1335 29.2781 27.8201 21.5156 38.5 21.5156C43.6721 21.5156 48.5295 23.3156 52.1804 26.5875C55.8312 29.8594 58.0763 34.2 58.0658 38.8219C58.0658 48.3656 49.1589 56.1281 38.4895 56.1281ZM49.1065 43.1719C48.5295 42.9094 45.6654 41.6531 45.1304 41.4844C44.5953 41.3062 44.2071 41.2219 43.819 41.7469C43.4308 42.2719 42.3188 43.4344 41.9725 43.7906C41.6368 44.1375 41.2906 44.1844 40.7136 43.9219C37.2935 42.3937 35.0484 41.1938 32.7929 37.7344C32.1949 36.8156 33.3908 36.8813 34.5029 34.8937C34.6917 34.5469 34.5973 34.2469 34.4504 33.9844C34.3036 33.7219 33.1391 31.1625 32.6565 30.1219C32.1844 29.1094 31.7018 29.25 31.3451 29.2313C31.0094 29.2125 30.6212 29.2125 30.233 29.2125C29.8449 29.2125 29.2154 29.3438 28.6804 29.8594C28.1453 30.3844 26.6451 31.6406 26.6451 34.2C26.6451 36.7594 28.7328 39.2344 29.0161 39.5813C29.3098 39.9281 33.1181 45.1781 38.9616 47.4375C42.6545 48.8625 44.1022 48.9844 45.9487 48.7406C47.0712 48.5906 49.3897 47.4844 49.8723 46.2656C50.3549 45.0469 50.3549 44.0063 50.208 43.7906C50.0717 43.5563 49.6835 43.425 49.1065 43.1719Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
      <div className={`${styles.model} ${model ? styles.open : ""}`}>
        <svg
          onClick={closeModal}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
        </svg>
        <img src={tempingSrc} alt={altTemporal} />
      </div>
      <Header />
      <div className={styles.container}>
        <div className={styles.contimages}>
          {data.map((item) => (
            <div
              key={item.id}
              className={styles.pics}
              onClick={() => getImg(item.imgSource,item.alt)}
            >
              <img src={item.imgSource} className={styles.image} alt={item.alt} />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GaleriaCompleta;
