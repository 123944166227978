import React from "react";
import Services from "./Services";
import SobreNosotros from "./SobreNosotros";
import Contacto from "./Contacto";
import Footer from "./Footer";
import Galeria from "./Galeria";

import Portrait from "./Portrait";

const Home = () => {
  return (
    <div>
      <Portrait />
      <Services />
      <Galeria />
      <SobreNosotros />
      <Contacto />
      <Footer />
    </div>
  );
};

//
export default Home;
