import { useState, useEffect, useRef } from "react";
import styles from "./formClient.module.css";
import LogoTME from "../LogoTME";
import Footer from "../../componets/Footer";
import { Validaciones, useInputHandles } from "../../utilities/InputControl";

const FormClient = () => {
  const nombre = useInputHandles({ type: "none" });
  const correo = useInputHandles({ type: "email" });
  const telefono = useInputHandles({ type: "phoneNumber" });
  const observaciones = useInputHandles({ type: "none" });
  const [tipoTrasporte, setTipoTrasporte] = useState(0);
  const [tipoCarga, setTipoCarga] = useState(0);
  const getDataExecutedRef = useRef(false);

  useEffect(() => {
    if (!getDataExecutedRef.current) {
      const data = sessionStorage.getItem("DatosContacto");

      if (data !== null) {
        const result = JSON.parse(data);
        nombre.handleChange(result.nombre);
        correo.handleChange(result.correo);
        telefono.handleChange(result.telefono);
        setTipoTrasporte(result.tipoDeTrasporte);
        setTipoCarga(result.tipoDeCarga);
        observaciones.handleChange(result.observaciones);
      }

      getDataExecutedRef.current = true; 
    }
  }, [correo,tipoTrasporte,tipoCarga,nombre,telefono,observaciones]);

  const selectTipoTrasporte = (event) => {
    const value = event.target.value;
    setTipoTrasporte(value);
  };

  const selectTipoCarga = (event) => {
    const value = event.target.value;
    setTipoCarga(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let inputs = [nombre, correo, telefono];
    let validado = false;

    for (let el of inputs) {
      const validacion = Validaciones(el);
      if (validacion) validado = true;
    }

    if (validado) return;

    try {
      const body = JSON.stringify({
        nombre: nombre.text,
        correo: correo.text,
        telefono: telefono.text,
        tipoTrasporte: tipoTrasporte,
        tipoCarga: tipoCarga,
        observaciones: observaciones.text,
      });

      console.log(body);

      const data = await fetch(
        "https://api-tme-soluciones.vercel.app/saveInfoClient",
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: body,
        }
      );

      if (!data.ok) {
        throw new Error("error al guardar al cliente");
      }

      const res = await data.json();
      console.log(res);
      //window.location.href = "https://wa.link/qsvfu5";
      window.location.href = "https://wa.link/zogn78";
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <a href="/">
        <div className={styles.logo}>
          <LogoTME />
        </div>
      </a>
      <div className={styles.deco1}>
        <svg
          viewBox="0 0 1273 1058"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1282.89 767.688C1282.89 767.688 1268.39 586.11 1188.42 520.869C1084.45 436.052 962.788 591.617 844.908 527.531C726.282 463.039 786.719 288.625 663.635 233.113C518.372 167.599 425.817 305.869 273.742 353.48C162.373 388.347 1.0003 441.144 1.0003 441.144"
            stroke="#69B32D"
            strokeWidth="3"
          />
          <path
            d="M983.044 516.335C988.475 515.333 993.691 518.923 994.693 524.354L995.661 529.599C996.664 535.03 993.073 540.245 987.642 541.248L985.588 541.627L976.497 543.305L940.526 549.944C935.095 550.947 929.879 547.357 928.877 541.926L927.909 536.681C926.906 531.25 930.497 526.034 935.928 525.032L983.044 516.335Z"
            fill="#264897"
          />
          <path
            d="M115.805 390.054C121.139 388.622 126.624 391.785 128.056 397.119L130.39 405.816C131.822 411.15 128.659 416.635 123.325 418.067L116.825 419.811L104.469 423.128L51.8727 437.248C46.5387 438.679 41.0539 435.516 39.622 430.182L37.2874 421.485C35.8555 416.151 39.0188 410.667 44.3528 409.235L115.805 390.054Z"
            fill="#264897"
          />
        </svg>
      </div>
      <h3 className={styles.title}>Datos Basicos</h3>
      <form>
        <div className={styles.contInput}>
          <label>Nombre Completo</label>
          <input
            value={nombre.text}
            onChange={(event) => {
              nombre.handleChange(event.target.value);
            }}
            onBlur={nombre.handleBlur}
            onFocus={nombre.handleFocus}
            type="text"
            placeholder="Escriba su Nombre completo"
            style={{ borderColor: nombre.error === "" ? "#264897" : "#cf0202" }}
          />
          <div className={styles.conLabel}>
            <span className={styles.TextError}>{nombre.error}</span>
          </div>
        </div>
        <div className={styles.contInput}>
          <label>Correo electrónico</label>
          <input
            value={correo.text}
            onChange={(event) => {
              correo.handleChange(event.target.value);
            }}
            onBlur={correo.handleBlur}
            onFocus={correo.handleFocus}
            type="text"
            placeholder="Escriba su Nombre completo"
            style={{ borderColor: correo.error === "" ? "#264897" : "#cf0202" }}
          />
          <div className={styles.conLabel}>
            <span className={styles.TextError}>{correo.error}</span>
          </div>
        </div>
        <div className={styles.contInput}>
          <label>Teléfono</label>
          <input
            value={telefono.text}
            type="text"
            placeholder="Escriba su numero de teléfono"
            onChange={(event) => {
              telefono.handleChange(event.target.value);
            }}
            onBlur={telefono.handleBlur}
            onFocus={telefono.handleFocus}
            style={{
              borderColor: telefono.error === "" ? "#264897" : "#cf0202",
            }}
          />
          <div className={styles.conLabel}>
            <span className={styles.TextError}>{telefono.error}</span>
          </div>
        </div>
        <div className={styles.contInput}>
          <label>Tipo de trasporte</label>
          <select onChange={selectTipoTrasporte} value={tipoTrasporte}>
            <option defaultValue={0} disabled selected>
              Tipo de Trasporte
            </option>
            <option value={1}>Furgon</option>
            <option value={2}>Carry</option>
            <option value={3}>Camion</option>
            <option value={4}>Grua</option>
            <option value={5}>Aereo</option>
          </select>
        </div>
        <div className={styles.contInput}>
          <label>Tipo de Carga</label>
          <select onChange={selectTipoCarga} value={tipoCarga}>
            <option defaultValue={0} disabled selected>
              Tipo de Carga
            </option>
            <option value={1}>Seca</option>
            <option value={2}>Refrigerada</option>
            <option value={3}>Gruas</option>
            <option value={4}>Maquinaria y equipos</option>
            <option value={5}>Aerea</option>
          </select>
        </div>
        <div className={styles.contInput}>
          <label>
            Observaciones{" "}
            <span style={{ color: "rgba(0,0,0,.3)" }}>(opcional)</span>
          </label>
          <textarea
            value={observaciones.text}
            onChange={(event) => {
              observaciones.handleChange(event.target.value);
            }}
            className={styles.observaciones}
          ></textarea>
        </div>

        <input
          onClick={handleSubmit}
          className={styles.botom}
          type="submit"
          value={"Contactanos"}
        />
      </form>
      <Footer />
    </div>
  );
};

export default FormClient;
